import { parseWebhookItemToRecord, parseWebhookRecordToItem } from "@/data/webhooks-helpers";

export function parseRequesterItemToRecord(clientRequester) {
  const { webhook: clientWebhook, ...rest } = clientRequester;

  const serverRequester = {
    perfil: "REQUISITANTE",
    webhook: parseWebhookItemToRecord(clientWebhook),
    ...rest,
  };

  return serverRequester;
}

export function parseRequesterRecordToItem(serverRequester) {
  const { webhook: serverWebhook, ...rest } = serverRequester;

  const clientRequester = rest;
  clientRequester.webhook = parseWebhookRecordToItem(serverWebhook);

  return clientRequester;
}
