<template>
  <div>
    <PdfManager
      v-model="fluxo.documentos"
      :field="camelCase(field, 'documentos')"
      :pdf-icon="documentoIcon"
      :max-file-quantity="15"
      :hide-upload="disabled"
      :hide-file-del="() => disabled"
      label="Documentos"
      :pdf-download="pdfDownload"
      :pdf-upload="pdfUpload"
      :pdf-delete="pdfDelete"
      @afterUpload="addDocumento"
      @afterDelete="delDocumento"
      v-on="$listeners"
    >
      <template #message>
        <InfoText>Documentos do fluxo que devem ser assinados.</InfoText>
      </template>
      <template #actions="{ pdf: documento, index, isUploading }">
        <FlowFormDocumentsTabManagerStamperButton
          :field="camelCase(field, 'documento', 'position', index)"
          :documento="documento"
          :fluxo="fluxo"
          :hide="disabled"
          :disabled="isUploading"
          :pdf-download="pdfDownload"
        />
      </template>
    </PdfManager>
  </div>
</template>

<script>
import { deleteFile, downloadFile, uploadFile } from "@/api";
import { hasAllStampsPositioned } from "@/data";
import { InfoText, PdfManager, camelCase } from "@/lib";
import { mapMutations } from "vuex";

import FlowFormDocumentsTabManagerStamperButton from "./flow-form-documents-tab-manager-stamper-button";

export default {
  components: {
    FlowFormDocumentsTabManagerStamperButton,
    InfoText,
    PdfManager,
  },
  data() {
    return {
      currentDocumento: null,
      pdfRoute: { endpoint: "/documentos", service: "controlador" },
    };
  },
  methods: {
    ...mapMutations("fluxosAdministrativo", ["addDocumento", "delDocumento"]),
    camelCase,
    documentoIcon(pdf) {
      const name = { name: "pdf" };
      if (hasAllStampsPositioned(pdf, this.fluxo)) return { ...name, fillColor: "var(--w-color-success-50)" };
      return { ...name, fillColor: "var(--w-color-warning-30)", tooltip: "posicionamento de assinatura pendente" };
    },
    pdfDelete(id) {
      return deleteFile({ id, route: this.pdfRoute });
    },
    pdfDownload({ name, path }) {
      return downloadFile({ name, path });
    },
    pdfUpload(file) {
      const meta = { idFluxo: this.fluxo.id };
      return uploadFile({ file, meta, route: this.pdfRoute });
    },
  },
  name: "FlowFormDocumentsTabManager",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    field: {
      default: "fluxo",
      type: String,
    },
    fluxo: {
      required: true,
      type: Object,
    },
  },
};
</script>
