import Vue from "vue";

import App from "./app";

export function initApp({ router, store }) {
  const app = new Vue({
    components: { App },
    render: h => h(App),
    router,
    store,
  });

  return app;
}
