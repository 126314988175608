<template>
  <FragmentBase>
    <ButtonIcon
      :field="field"
      :hide="composedHide"
      :disabled="composedDisabled"
      icon="edit-square"
      tooltip="apontar posição do selo de assinatura"
      @click="handleClick"
    />
    <FlowFormDocumentsTabManagerStamperModal
      v-if="hasModal"
      ref="stamper"
      :field="camelCase('stamper', 'modal')"
      :fluxo="fluxo"
      :documento="documento"
      :pdf-download="pdfDownload"
    />
  </FragmentBase>
</template>

<script>
import { hasAssinante } from "@/data";
import { ButtonIcon, FragmentBase, camelCase } from "@/lib";

import FlowFormDocumentsTabManagerStamperModal from "./flow-form-documents-tab-manager-stamper-modal";

export default {
  components: {
    ButtonIcon,
    FlowFormDocumentsTabManagerStamperModal,
    FragmentBase,
  },
  computed: {
    composedDisabled() {
      return this.disabled || !hasAssinante(this.fluxo);
    },
    composedHide() {
      return this.hide;
    },
    hasModal() {
      return !(this.composedHide || this.composedDisabled);
    },
  },
  methods: {
    camelCase,
    handleClick() {
      this.$refs.stamper.open();
    },
  },
  name: "FlowFormDocumentsTabManagerStamperButton",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    documento: {
      default: null,
      type: Object,
    },
    field: {
      default: null,
      type: String,
    },
    fluxo: {
      default: null,
      type: Object,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    pdfDownload: {
      required: true,
      type: Function,
    },
  },
};
</script>
