<template>
  <DialogForm
    :default-payload="defaultGrupo"
    :external-errors="externalErrors"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload }">
      <RowForm>
        <ColBase>
          <ControlInput
            v-model="payload.nome"
            field="nome"
            rules="required|min:3|max:255"
            label="Nome"
            :disabled="editDisabled"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase>
          <ControlSelectMulti
            v-model="payload.gestores"
            field="gestores"
            :options="gestores"
            value-field="id"
            text-field="nome"
            rules="required"
            :rows="3"
            label="Gestores"
            placeholder="Escolha os demais gestores desse grupo."
            :disabled="editDisabled"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase>
          <ControlTextarea
            v-model="payload.descricao"
            field="descricao"
            rules="required|min:3|max:255"
            label="Descrição"
            :disabled="editDisabled"
          />
        </ColBase>
      </RowForm>
      <RowForm v-if="!hideAtivo">
        <ColBase>
          <ControlCheckbox
            v-model="payload.ativo"
            field="ativo"
            :hide="hideAtivo"
            placeholder="Ativar Grupo"
            switch
            :disabled="editDisabled"
          />
        </ColBase>
      </RowForm>
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
  </DialogForm>
</template>

<script>
import {
  ColBase,
  ControlCheckbox,
  ControlInput,
  ControlSelectMulti,
  ControlTextarea,
  DialogForm,
  RowForm,
} from "@/lib";
import { mapGetters } from "vuex";

export default {
  components: {
    ColBase,
    ControlCheckbox,
    ControlInput,
    ControlSelectMulti,
    ControlTextarea,
    DialogForm,
    RowForm,
  },
  computed: {
    ...mapGetters("gestores", { gestores: "getAllItems" }),
  },
  name: "GroupForm",
  props: {
    defaultGrupo: {
      default: () => ({}),
      type: Object,
    },
    editDisabled: {
      default: false,
      type: Boolean,
    },
    externalErrors: {
      default: null,
      type: Object,
    },
    hideAtivo: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
