var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("IconBaseSvg", {
    attrs: {
      d: "M7.5 9C9.86719 9 11.7857 6.98555 11.7857 4.5C11.7857 2.01445 9.86719 0 7.5 0C5.13281 0 3.21429 2.01445 3.21429 4.5C3.21429 6.98555 5.13281 9 7.5 9ZM10.7076 10.1461L9.10714 16.875L8.03571 12.0938L9.10714 10.125H5.89286L6.96429 12.0938L5.89286 16.875L4.29241 10.1461C1.90513 10.2656 0 12.3152 0 14.85V16.3125C0 17.2441 0.719866 18 1.60714 18H13.3929C14.2801 18 15 17.2441 15 16.3125V14.85C15 12.3152 13.0949 10.2656 10.7076 10.1461Z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }