import {
  createAuthRoute,
  createChangelogRoute,
  createClientsAddRoute,
  createClientsEditRoute,
  createClientsListRoute,
  createFlowsArchivedApprovalsRoute,
  createFlowsArchivedListRoute,
  createFlowsArchivedViewRoute,
  createFlowsEngagementAcknowledgedRoute,
  createFlowsEngagementApprovalRoutes,
  createFlowsEngagementApprovalsRoute,
  createFlowsEngagementDesktopRoute,
  createFlowsEngagementFeedbackRoute,
  createFlowsEngagementListRoute,
  createFlowsEngagementSerproIdRoutes,
  createFlowsManagementAddRoute,
  createFlowsManagementApprovalsRoute,
  createFlowsManagementEditRoute,
  createFlowsManagementListRoute,
  createFlowsManagementViewRoute,
  createGroupsAddRoute,
  createGroupsEditRoute,
  createGroupsListRoute,
  createGroupsMembersListRoute,
  createGroupsViewRoute,
  createMaintenanceRoute,
  createManagersAddRoute,
  createManagersEditRoute,
  createManagersListRoute,
  createNotFoundRoute,
  createRequestersAddRoute,
  createRequestersEditRoute,
  createRequestersListRoute,
  createRequestersWebhookRoute,
  createStakeholdersAddRoute,
  createStakeholdersEditRoute,
  createStakeholdersListRoute,
} from "@/views/pages";

const authRoute = createAuthRoute();
const notFoundRoute = createNotFoundRoute();

export const routes = [
  { path: "/", redirect: authRoute.path },

  authRoute,

  notFoundRoute,
  createMaintenanceRoute(),

  createChangelogRoute(),

  createClientsListRoute(),
  createClientsAddRoute(),
  createClientsEditRoute(),

  createFlowsArchivedListRoute(),
  createFlowsArchivedViewRoute(),
  createFlowsArchivedApprovalsRoute(),

  createFlowsEngagementListRoute(),
  createFlowsEngagementAcknowledgedRoute(),
  createFlowsEngagementApprovalsRoute(),
  ...createFlowsEngagementApprovalRoutes(),
  createFlowsEngagementFeedbackRoute(),
  createFlowsEngagementDesktopRoute(),
  ...createFlowsEngagementSerproIdRoutes(),

  createFlowsManagementListRoute(),
  createFlowsManagementAddRoute(),
  createFlowsManagementApprovalsRoute(),
  createFlowsManagementEditRoute(),
  createFlowsManagementViewRoute(),

  createGroupsAddRoute(),
  createGroupsEditRoute(),
  createGroupsListRoute(),
  createGroupsMembersListRoute(),
  createGroupsViewRoute(),

  createManagersListRoute(),
  createManagersEditRoute(),
  createManagersAddRoute(),

  createRequestersAddRoute(),
  createRequestersEditRoute(),
  createRequestersListRoute(),
  createRequestersWebhookRoute(),

  createStakeholdersAddRoute(),
  createStakeholdersEditRoute(),
  createStakeholdersListRoute(),

  { path: "*", redirect: notFoundRoute.path },
];
