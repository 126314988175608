<template>
  <div
    v-if="!hidden"
    class="tab-accordion"
  >
    <TabAccordionHeader
      :parent-id="tabId"
      :disabled="disabled"
      :title="headerTitle"
      :subtitle="headerSubtitle"
      :icon-name="headerIconName"
      :icon-badge="headerIconBadge"
    />
    <BCollapse
      :id="tabId"
      :visible="show"
      :accordion="accordion"
      role="tabpanel"
      class="pt-3 px-3 pb-2"
    >
      <div>
        <slot />
      </div>
    </BCollapse>
  </div>
</template>

<script>
import { camelCase } from "@/lib/meta";

import { BCollapse } from "../bv";
import TabAccordionHeader from "./tab-accordion-header";

export default {
  components: { BCollapse, TabAccordionHeader },
  computed: {
    tabId() {
      return camelCase("tab", this.field);
    },
  },
  name: "TabAccordion",
  props: {
    accordion: {
      required: true,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    field: {
      required: true,
      type: String,
    },
    headerIconBadge: {
      default: null,
      type: [String, Number],
    },
    headerIconName: {
      default: null,
      type: String,
    },
    headerSubtitle: {
      default: null,
      type: String,
    },
    headerTitle: {
      required: true,
      type: String,
    },
    hidden: {
      default: false,
      type: Boolean,
    },
    show: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
