import Vue from "vue";
import VueRouter from "vue-router";

import { createGuardRoute } from "./guard-route";
import { routes } from "./routes";
import { createDefaultRoute } from "./routes-default";

export function initRouter(store) {
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: "history",
    routes,
  });
  router.addRoute(createDefaultRoute(store));
  router.beforeEach(createGuardRoute(store));

  return router;
}
