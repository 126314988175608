import { request } from "@/api";
import { createConnectedStoreModule } from "@/lib";

export const webhooksStore = createConnectedStoreModule({
  actions: {
    async test({ dispatch }, idRequisitante) {
      const payload = {
        endpoint: `/webhook/validar/${idRequisitante}`,
        method: "get",
      };
      return dispatch("requestAction", payload);
    },
  },
  apiService: "autenticador",
  request,
});
