<template>
  <SideBarItem
    :data-test="dataTest"
    :level="2"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span>
      <AppIcon
        v-if="icon"
        :name="icon"
        :fill-color="isCurrent ? 'var(--w-color-success-50)' : 'var(--w-color-neutral-10)'"
      />
      {{ text.toUpperCase() }}
    </span>
  </SideBarItem>
</template>

<script>
import { camelCase } from "@/lib/meta";

import { AppIcon } from "../icons";
import SideBarItem from "./side-bar-item";

export default {
  components: { AppIcon, SideBarItem },
  computed: {
    isCurrent() {
      return this.$route.path === this.$attrs.to;
    },
  },
  methods: {
    camelCase,
  },
  name: "SideBarLink",
  props: {
    dataTest: {
      default: null,
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },
};
</script>
