export function listCrumbsHome() {
  return [{ text: "Página inicial", to: "/" }];
}

export function listCrumbsClients() {
  return [
    ...listCrumbsHome(),
    { text: "Clientes", to: "/clients/list" },
  ];
}

export function listCrumbsManagers() {
  return [
    ...listCrumbsHome(),
    { text: "Gestores", to: "/managers/list" },
  ];
}

export function listCrumbsStakeholders() {
  return [
    ...listCrumbsHome(),
    { text: "Interessados", to: "/stakeholders/list" },
  ];
}

export function listCrumbsGroups() {
  return [
    ...listCrumbsHome(),
    { text: "Grupos", to: "/groups/list" },
  ];
}

export function listCrumbsRequesters() {
  return [
    ...listCrumbsHome(),
    { text: "Requisitantes", to: "/requesters/list" },
  ];
}

const textFluxos = { text: "Fluxos de Assinatura" };

export function listCrumbsFlowsManagement() {
  return [
    ...listCrumbsHome(),
    { ...textFluxos, to: "/flows-management/list" },
  ];
}

export function listCrumbsFlowsEngagement() {
  return [
    ...listCrumbsHome(),
    { ...textFluxos, to: "/flows-engagement/list" },
  ];
}

export function listCrumbsFlowsArchived() {
  return [
    ...listCrumbsHome(),
    { text: "Fluxos Arquivados", to: "/flows-archived/list" },
  ];
}
