<template>
  <ButtonIcon
    :field="camelCase('resposta', field)"
    :hide="hide"
    icon="list-check"
    tooltip="visualizar situação das respostas"
    @click="handleClick"
  />
</template>

<script>
import { isCriado } from "@/data";
import { ButtonIcon, camelCase } from "@/lib";

export default {
  components: { ButtonIcon },
  computed: {
    hide() {
      return isCriado(this.fluxo);
    },
  },
  methods: {
    camelCase,
    handleClick() {
      if (!this.basePath) return null;
      this.$router.push(`${this.basePath}/approvals/${this.fluxo.id}`);
    },
  },
  name: "FlowApprovalsButton",
  props: {
    basePath: {
      default: null,
      type: String,
    },
    field: {
      default: null,
      type: [String, Number],
    },
    fluxo: {
      default: () => ({}),
      type: Object,
    },
  },
};
</script>
