<template>
  <FragmentBase>
    <BButton
      v-for="enumState in enumsState"
      :id="camelCase('searchEnum', enumState.text)"
      :key="enumState.text"
      :pressed.sync="enumState.isActive"
      pill
      :variant="decideVariant(enumState.color)"
      class="button-status mr-1"
    >
      {{ enumState.text.toUpperCase() }}
    </BButton>
  </FragmentBase>
</template>

<script>
import { camelCase } from "@/lib/meta";

import { BButton } from "../bv";
import { FragmentBase } from "../layout";

export default {
  components: { BButton, FragmentBase },
  data() {
    return {
      enumsState: this.convertConfigEnumsState(this.config),
    };
  },
  methods: {
    camelCase,
    convertConfigEnumsState(config) {
      const enumsState = config.map(({ isActive = false, ...rest }) => ({
        // isActive precisa estar declarado no objeto para que possa ser sincronizado com o estado de pressionado do botão.
        isActive,
        ...rest,
      }));
      return enumsState;
    },
    decideVariant(color = "primary") {
      return `outline-${color}`;
    },
    handleToggle() {
      const selectedIsEnumCallbacks = this.enumsState
        .filter(({ isActive }) => isActive)
        .map(({ isEnum }) => isEnum);
      this.$emit("input", selectedIsEnumCallbacks);
    },
  },
  name: "SearchEnum",
  props: {
    config: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: Array,
    },
  },
  watch: {
    enumsState: {
      deep: true,
      handler: "handleToggle",
      immediate: true,
    },
  },
};
</script>

<style scoped>
.button-status {
  height: min-content;
  font-size: var(--w-font-size-10) !important;
  font-weight: var(--w-font-weight-50);
}
</style>
