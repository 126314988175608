<template>
  <ToolbarSection>
    <ButtonIcon
      :field="camelCase('first', field)"
      :disabled="innerDisabled || pagesCurrent === 1"
      icon="first"
      @click="$emit('update:pages-current', 1)"
    />
    <ButtonIcon
      :field="camelCase('previous', field)"
      :disabled="innerDisabled || pagesCurrent === 1"
      icon="previous"
      @click="$emit('update:pages-current', pagesCurrent - 1)"
    />
    <ButtonIcon
      :field="camelCase('current', field)"
      disabled
      :label="pagesCurrent || 1"
    />
    <ButtonIcon
      :field="camelCase('next', field)"
      :disabled="innerDisabled || pagesCurrent === pagesTotal"
      icon="next"
      @click="$emit('update:pages-current', pagesCurrent + 1)"
    />
    <ButtonIcon
      :field="camelCase('last', field)"
      :disabled="innerDisabled || pagesCurrent === pagesTotal"
      icon="last"
      @click="$emit('update:pages-current', pagesTotal)"
    />
  </ToolbarSection>
</template>

<script>
import { camelCase } from "@/lib/meta";

import { ButtonIcon, ToolbarSection } from "../buttons";

export default {
  components: { ButtonIcon, ToolbarSection },
  computed: {
    innerDisabled() {
      return this.disabled || !this.pagesTotal || !this.pagesCurrent;
    },
  },
  methods: {
    camelCase,
  },
  name: "PdfDocumentToolbarPages",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    field: {
      default: "pages",
      type: String,
    },
    pagesCurrent: {
      default: null,
      type: Number,
    },
    pagesTotal: {
      default: null,
      type: Number,
    },
  },
};
</script>
