import {
  createAuthRoute,
  createFlowsEngagementListRoute,
  createFlowsManagementListRoute,
  createManagersListRoute,
  createRequestersListRoute,
} from "@/views/pages";
import { ROUTING_ACCESSES } from "@/views/widgets";

export function createDefaultRoute(store) {
  return {
    path: "/default",
    redirect: () => solveDefaultPath(store.getters["auth/perfil"]),
  };
}

function solveDefaultPath(perfil) {
  const authRoute = createAuthRoute();
  const engagementListRoute = createFlowsEngagementListRoute();
  const managementListRoute = createFlowsManagementListRoute();
  const managersListRoute = createManagersListRoute();
  const requestersListRoute = createRequestersListRoute();

  const routeAccessesDefaults = {
    [ROUTING_ACCESSES.ADMINISTRATOR]: managersListRoute.path,
    [ROUTING_ACCESSES.MANAGER]: requestersListRoute.path,
    [ROUTING_ACCESSES.NONE]: authRoute.path,
    [ROUTING_ACCESSES.REQUESTER]: managementListRoute.path,
    [ROUTING_ACCESSES.STAKEHOLDER]: engagementListRoute.path,
  };

  return routeAccessesDefaults[perfil];
}
