var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "PdfManager",
        _vm._g(
          {
            attrs: {
              field: _vm.camelCase(_vm.field, "documentos"),
              "pdf-icon": _vm.documentoIcon,
              "max-file-quantity": 15,
              "hide-upload": _vm.disabled,
              "hide-file-del": () => _vm.disabled,
              label: "Documentos",
              "pdf-download": _vm.pdfDownload,
              "pdf-upload": _vm.pdfUpload,
              "pdf-delete": _vm.pdfDelete,
            },
            on: {
              afterUpload: _vm.addDocumento,
              afterDelete: _vm.delDocumento,
            },
            scopedSlots: _vm._u([
              {
                key: "message",
                fn: function () {
                  return [
                    _c("InfoText", [
                      _vm._v("Documentos do fluxo que devem ser assinados."),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "actions",
                fn: function ({ pdf: documento, index, isUploading }) {
                  return [
                    _c("FlowFormDocumentsTabManagerStamperButton", {
                      attrs: {
                        field: _vm.camelCase(
                          _vm.field,
                          "documento",
                          "position",
                          index
                        ),
                        documento: documento,
                        fluxo: _vm.fluxo,
                        hide: _vm.disabled,
                        disabled: isUploading,
                        "pdf-download": _vm.pdfDownload,
                      },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.fluxo.documentos,
              callback: function ($$v) {
                _vm.$set(_vm.fluxo, "documentos", $$v)
              },
              expression: "fluxo.documentos",
            },
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }