<template>
  <ModalBase
    ref="modal"
    show-action-divider
    size="lg"
  >
    <template #body>
      <OverlayBase :is-busy="isLoadingSeloBackground">
        <RowForm>
          <ColBase>
            <ControlSelectSingle
              v-model="currentAssinanteCodigo"
              field="assinante"
              :options="assinantesAsOptions"
              value-field="codigo"
              text-field="label"
              label="Assinante"
              helper-text="Escolha aqui o assinante e depois clique na página abaixo para posicionar o selo de assinatura"
            />
          </ColBase>
        </RowForm>
        <PdfDocument
          v-if="documento"
          :file-path="documento.path"
          :file-name="documento.nome"
          :overlays="overlays"
          :pdf-download="pdfDownload"
          @documentClick="handleDocumentClick"
        />
      </OverlayBase>
    </template>
    <template #actions="{ save: back }">
      <ButtonSecondary
        :field="camelCase(field, 'clear')"
        label="Limpar posicionamentos"
        @click="resetSelos"
      />
      <ButtonPrimary
        :field="camelCase(field, 'back')"
        label="Retornar ao Fluxo"
        @click="back()"
      />
    </template>
  </ModalBase>
</template>

<script>
import { extractAssinantes, hasAssinante } from "@/data";
import {
  ButtonPrimary,
  ButtonSecondary,
  ColBase,
  ControlSelectSingle,
  ModalBase,
  OverlayBase,
  PdfDocument,
  RowForm,
  camelCase,
  truncateString,
} from "@/lib";

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary,
    ColBase,
    ControlSelectSingle,
    ModalBase,
    OverlayBase,
    PdfDocument,
    RowForm,
  },
  computed: {
    assinantes() {
      return extractAssinantes(this.fluxo);
    },
    assinantesAsOptions() {
      return this.assinantes.map((a) => {
        const status = this.selos.find(s => s.codigoAssinante === a.codigo)
          ? "🟢"
          : "⚪";
        return {
          codigo: a.codigo,
          label: `${status}  ${this.shortName(a.nome)}`,
        };
      });
    },
    overlays() {
      const overlays = this.selos.map((selo) => {
        const { codigoAssinante, pagina: page, x, y } = selo;

        const { nome: longNome } = this.assinantes.find(
          a => a.codigo === codigoAssinante,
        );
        const nome = this.shortName(longNome);
        const date = "dd/mm/aaaa";
        const image = this.createImage({ date, nome });

        return {
          image,
          page,
          x,
          y,
        };
      });
      return overlays;
    },
    selos() {
      if (!this.documento) return [];
      if (!Array.isArray(this.documento.selos)) this.resetSelos();
      return this.documento.selos;
    },
  },
  created() {
    this.loadSeloBackground();
  },
  data() {
    return {
      background: null,
      currentAssinanteCodigo: null,
      isLoadingSeloBackground: true,
    };
  },
  methods: {
    camelCase,
    createImage({ date, nome }) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const { height, image, width } = this.background;
      context.drawImage(image, 0, 0, width, height);

      const fontSize = 7;
      context.font = `${fontSize}px 'Courier Prime', monospace`;

      const left = 5;
      const topStart = 25;
      const topStep = 10;

      context.fillText(nome, left, topStart + fontSize);
      context.fillText(date, left, topStart + topStep + fontSize);

      return canvas;
    },
    handleDocumentClick({
      height: pageHeight,
      page,
      width: pageWidth,
      x: clickedX,
      y: clickedY,
    }) {
      if (!this.currentAssinanteCodigo) return;

      const { height: seloHeight, width: seloWidth } = this.background;
      const x
        = clickedX + seloWidth > pageWidth ? pageWidth - seloWidth : clickedX;
      const y
        = clickedY + seloHeight > pageHeight ? pageHeight - seloHeight : clickedY;

      const newSelo = {
        altura: seloHeight,
        codigoAssinante: this.currentAssinanteCodigo,
        largura: seloWidth,
        pagina: page,
        x,
        y,
      };

      const position = this.documento.selos.findIndex(
        s => s.codigoAssinante === newSelo.codigoAssinante,
      );
      if (position === -1) {
        this.documento.selos.push(newSelo);
      }
      else {
        this.$set(this.documento.selos, position, newSelo);
      }
    },
    loadSeloBackground() {
      const imageSrc = "/public/selo-neosigner.png";
      const imageWidth = 145;
      const imageHeight = 60;

      this.isLoadingSeloBackground = true;
      const image = document.createElement("img");
      image.onload = () => {
        this.background = {
          height: imageHeight,
          image,
          width: imageWidth,
        };
        this.isLoadingSeloBackground = false;
      };
      image.src = imageSrc;
    },
    open() {
      return this.$refs.modal.show();
    },
    resetSelos() {
      this.$set(this.documento, "selos", []);
    },
    setCurrentAssinante() {
      if (!hasAssinante(this.fluxo)) return;

      const isCurrentAssinante = a =>
        a.codigo === this.currentAssinanteCodigo;
      if (this.assinantes.find(isCurrentAssinante)) return;

      this.currentAssinanteCodigo = this.assinantes[0].codigo;
    },
    shortName(name) {
      return truncateString(name, { abbreviate: true, limit: 30 });
    },
    syncAssinantesAndSelos() {
      const assinantes = this.assinantes;
      const selos = this.selos;
      const assinantesSelos = selos.map(s => s.codigoAssinante);
      assinantesSelos.forEach((codigo) => {
        if (!assinantes.find(a => a.codigo === codigo)) {
          const seloPos = this.selos.findIndex(
            s => s.codigoAssinante === codigo,
          );
          selos.splice(seloPos, 1);
        }
      });
    },
  },
  name: "FlowFormDocumentsTabManagerStamperModal",
  props: {
    documento: {
      default: null,
      type: Object,
    },
    field: {
      default: "stamper",
      type: String,
    },
    fluxo: {
      default: null,
      type: Object,
    },
    pdfDownload: {
      required: true,
      type: Function,
    },
  },
  watch: {
    assinantes: {
      handler() {
        this.syncAssinantesAndSelos();
        this.setCurrentAssinante();
      },
      immediate: true,
    },
  },
};
</script>
